$(function() {
  $('header').on('click', function() {
    if (isSp()) {
      $('.nav-block, .menu-trigger').toggleClass('open');
    }
  });
});

function isPc() {
  if ($(window).width() > 1020) {
    return true;
  } else {
    return false;
  }
}

function isTablet() {
  if ($(window).width() <= 1020) {
    return true;
  } else {
    return false;
  }
}

function isSp() {
  if ($(window).width() <= 380) {
    return true;
  } else {
    return false;
  }
}
